<template>
  <view class="index restaurant-detail">
    <!-- <van-nav-bar title="餐厅详情" /> -->
    <van-swipe :autoplay="4000">
      <van-swipe-item v-for="imgUrl in images" :key="imgUrl">
        <van-image
          width="100%"
          height="180px"
          fit="cover"
          lazy-load
          :src="resetImageUrl(imgUrl,0,0)"
        />
      </van-swipe-item>
    </van-swipe>

    <div class="detail-info">
      <div class="rest-name"> {{ restaurant.name }}</div>
      <div class="rest-open">
        <van-icon name="clock-o" color="#000" />  
        <span>{{openingTime}}</span>
      </div>
      <div class="rest-address">
        <van-icon name="location-o" color="#000" /> {{restaurant.cityName}}{{ restaurant.address }}
      </div>
    </div>

    <div class="restaurant-intro">
        <h3>餐厅介绍</h3>
        <div>{{restaurant.intro}}</div>
    </div>  

    <div class="resturant-action">
      <van-button class="btn-order" @click="onBooking">
        <van-icon name="completed" /> 立即订座
      </van-button>
    </div>

  </view>
</template>

<script>
import { reactive, toRefs, onMounted } from "vue";
import { useRoute, useRouter} from "vue-router";
import RestaurantService from "../api/restaurant-service";
import RestaurantOpeningHours from "./restaurant-opening-hours";
import { resetImageUrl } from "../utils";
import "../css/restaurant-details.scss";

export default {
  setup() {
    const state = reactive({
      restaurant: {},
      activeNames: ["1"],
      images: [],
      openingHours: null,
      openingTime: null
    });
    const router = useRouter();
    const { id } = useRoute().params;
    const onBooking =()=> router.push("/restaurant/" + state.restaurant.id + "/booking");
    const buildOpeningHours = (hours) => {
      //  console.log(hours);
       RestaurantOpeningHours.buildOpeningHours(state , hours);
    }

    onMounted(() => RestaurantService.getRestaurantDetails(id)
      .then(r => {
        state.restaurant = r;
        state.images = [r.imageUrl];
        r.images.forEach(url => state.images.push(url))
        return RestaurantService.getRestaurantOpeningHours(id)
      }).then(buildOpeningHours)
    );
    return {
      ...toRefs(state),
      onBooking,
      resetImageUrl
    };
  },
};
</script>
